import { BonusType, ValidTokenBonus } from '@arland-bmnext/api-data'
import { faRocket, faShield, faStar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { useBetslipContext } from '../../../context/betslip.context'
import useUserCurrency from '../../../hooks/useUserCurrency'
import { floorNumber, formatMoney, formatOddValue, formatPercentage } from '../../../util/number'
import Tag from '../../core/Tag'
import { faFireFlameCurved } from '@fortawesome/pro-solid-svg-icons'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

type BetslipTokenBonusSelectorItemProps = {
  tokenBonus: ValidTokenBonus
  isActive: boolean
  onToggleTokenBonus: () => void
}
const BetslipTokenBonusSelectorItem = ({
  tokenBonus,
  isActive,
  onToggleTokenBonus,
}: BetslipTokenBonusSelectorItemProps) => {
  const { t } = useTranslation('bets')
  const currency = useUserCurrency()
  const expirationDuration = dayjs.duration(dayjs(tokenBonus.expireDate).diff(dayjs()))
  const bonusExpires = expirationDuration.asHours() > 0
  const expirationInDays = floorNumber(expirationDuration.asDays(), 0)
  const expirationInHours = floorNumber(expirationDuration.asHours(), 0)

  const getTokenBonusIcon = () => {
    switch (tokenBonus.bonusType) {
      case BonusType.BetBoostToken:
        return faRocket
      case BonusType.FreeBetToken:
        return faStar
      case BonusType.RiskFreeBetToken:
        return faShield
      case BonusType.SuperBoostToken:
        return faFireFlameCurved
    }
  }

  const getTokenValueLabel = () => {
    switch (tokenBonus.bonusType) {
      case BonusType.BetBoostToken:
        return formatPercentage(tokenBonus.boosterPercentage)
      case BonusType.FreeBetToken:
        return formatMoney(tokenBonus.freeBetTokenAmount, currency)
      case BonusType.SuperBoostToken:
        return formatOddValue(tokenBonus.boosterOddValue)
      default:
        return null
    }
  }

  return (
    <div
      className={`betslip-token-selector-item flex space-x-2 items-center px-4 py-[10px] rounded-full whitespace-nowrap transition-colors cursor-pointer relative ${
        isActive ? 'betslip-token-selector-item-active bg-primary text-primaryContrast' : 'bg-gray-500 bg-opacity-10'
      }`}
      onClick={onToggleTokenBonus}
    >
      <FontAwesomeIcon
        icon={getTokenBonusIcon()}
        className={`betslip-token-selector-item-icon ${
          isActive ? 'betslip-token-selector-item-icon-active text-primaryContrast' : 'text-primary'
        }`}
      />
      <span className="betslip-token-selector-item-name text-xs font-semibold">
        {tokenBonus.displayName ?? tokenBonus.bonusName}
      </span>

      {getTokenValueLabel() != null && (
        <Tag
          className={`betslip-token-selector-item-tag ${
            isActive ? 'betslip-token-selector-item-tag-active bg-primaryContrast text-primary' : ''
          }`}
          label={getTokenValueLabel()}
        />
      )}

      {bonusExpires && !isActive && (
        <div className="betslip-token-selector-item-expiry absolute -top-[6px] right-4">
          <Tag
            label={
              expirationInHours > 72
                ? t('BetSlip.bonusToken.expiresInDays', { days: expirationInDays })
                : t('BetSlip.bonusToken.expiresInHours', { hours: expirationInHours })
            }
            textColor={'text-primaryContrast'}
            backgroundColor={'bg-primary'}
            className="betslip-token-selector-item-expiry-tag px-1 !h-[12px] !text-[8px]"
          />
        </div>
      )}
    </div>
  )
}

const BetslipTokenBonusSelector = () => {
  const betslipContext = useBetslipContext()
  const tokenBonuses = betslipContext.validTokenBonuses()

  const onToggleTokenBonus = (givenBonusId: number) => {
    betslipContext.toggleTokenBonus(givenBonusId)
  }

  return (
    <div className={'betslip-token-selector flex space-x-2 w-full py-2 overflow-x-auto relative'}>
      {tokenBonuses?.map((tokenBonus) => (
        <BetslipTokenBonusSelectorItem
          tokenBonus={tokenBonus}
          isActive={tokenBonus.givenBonusId === betslipContext.selectedTokenBonus?.givenBonusId}
          onToggleTokenBonus={() => onToggleTokenBonus(tokenBonus.givenBonusId)}
          key={tokenBonus.givenBonusId}
        />
      ))}
    </div>
  )
}

export default BetslipTokenBonusSelector
