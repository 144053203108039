import { PlaceBetSlipResultType, CheckBetSlipErrorType } from '@arland-bmnext/api-data'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { BetslipState, useBetslipContext } from '../../../context/betslip.context'
import { EnumTranslator } from '../../../util/enum-translator'
import { ErrorMessage } from '../../core/Message'

type BetslipWarningsProps = {
  betslipState: BetslipState
}

export const BetslipWarnings = ({ betslipState }: BetslipWarningsProps) => {
  const { t } = useTranslation('bets')
  const betslipContext = useBetslipContext()
  const checkErrors = betslipContext.checkBetSlipErrors
  const placeErrors = betslipContext.placeBetSlipErrors

  const BetslipWarning = ({ children }) => {
    return <ErrorMessage className="betslip-warning w-full !mb-0 rounded-none">{children}</ErrorMessage>
  }

  const PlaceBetSlipError = ({ placeResultType }) => {
    return EnumTranslator(t, PlaceBetSlipResultType, placeResultType)
  }

  const CheckBetSlipErrors = ({ errors }) => {
    return (
      <>
        {errors?.map((error, index) => {
          return (
            <div className="check-bet-error flex w-full space-x-2 items-center" key={index}>
              {EnumTranslator(t, CheckBetSlipErrorType, error.type)}
            </div>
          )
        })}
      </>
    )
  }

  return (
    <>
      {betslipState === BetslipState.Initial && checkErrors?.length > 0 && (
        <div className="betslip-warnings flex w-full">
          <BetslipWarning>{checkErrors?.length > 0 && <CheckBetSlipErrors errors={checkErrors} />}</BetslipWarning>
        </div>
      )}

      {betslipState === BetslipState.Initial && placeErrors != null && placeErrors.length != 0 && (
        <>
          {placeErrors?.map((placeError) => (
            <React.Fragment key={placeError}>
              {placeError != PlaceBetSlipResultType.BetSlipCheckFailed && (
                <div className="betslip-warnings flex w-full">
                  <BetslipWarning>
                    <PlaceBetSlipError placeResultType={placeError} />
                  </BetslipWarning>
                </div>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </>
  )
}
