import { AccountType } from '@arland-bmnext/api-data'
import useTranslation from 'next-translate/useTranslation'
import { useBetslipContext } from '../../../context/betslip.context'
import { useCommonContext } from '../../../context/common.context'
import { isLoggedInCustomer, useUser, useUserAccounts } from '../../../lib/user'
import { formatMoney } from '../../../util/number'
import Switch from '../../forms/elements/Switch'

export const BetslipAccountSelector = () => {
  const { t } = useTranslation('bets')
  const betslipContext = useBetslipContext()
  const { getCurrencyById } = useCommonContext()
  const { user } = useUser()
  const { freebetAccount } = useUserAccounts()

  return (
    <div className="betslip-account-selector flex justify-between items-center">
      {isLoggedInCustomer(user) && freebetAccount?.balance > 0 && (
        <>
          <div
            className="betslip-account-selector-labels flex flex-col"
            onClick={freebetAccount?.balance > 0 && freebetAccount != null ? betslipContext.toggleAccount : null}
          >
            <span className="betslip-account-selector-label-title font-bold text-xs">{t('BetSlip.freebetSelectorTitle')}</span>
            <span className="betslip-account-selector-label-balance font-semibold text-xxs opacity-60">
              {t('BetSlip.freebetSelectorCredits', {
                freebetBalance: formatMoney(freebetAccount?.balance, getCurrencyById(freebetAccount?.currencyId)),
              })}
            </span>
          </div>
          <Switch
            enabled={betslipContext.account?.type === AccountType.FreeBet}
            onClick={betslipContext.toggleAccount}
            handleColor={'bg-betslipContrast'}
            disabled={freebetAccount?.balance <= 0 || freebetAccount == null}
          />
        </>
      )}
    </div>
  )
}
