import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { useBetslipContext } from '../../../context/betslip.context'
import useUserCurrency from '../../../hooks/useUserCurrency'
import { BetTypes } from '../../../services/bet/bet.models'
import { getIntlCurrencySlot, getIntlSeparator, parseIntlNumberFormat } from '../../../util/internationalization'
import { floorNumber } from '../../../util/number'

const BetSlipTotalStakeSelector = ({ disabled, inputClassName = '', wrapperClassName = '' }) => {
  const { t } = useTranslation('bets')
  const betslipContext = useBetslipContext()
  const currency = useUserCurrency()
  const currencySymbol = currency?.symbol ?? currency?.shortSign
  const currencySlot = getIntlCurrencySlot()
  const stake = betslipContext.chargedAmount > 0 ? betslipContext.chargedAmount : betslipContext.stake

  const [betslipStake, setBetslipStake] = useState(null)
  const [betslipStakeDisplayValue, setBetslipStakeDisplayValue] = useState(null)

  useEffect(() => {
    setBetslipStake(stake)
    setBetslipStakeDisplayValue(stake)
  }, [betslipContext.chargedAmount, betslipContext.stake])

  const onStakeChange = (event: any) => {
    setBetslipStake((event?.target?.value as string)?.replace(currencySymbol, '') as any)
    setBetslipStakeDisplayValue(event?.target?.value as string)
  }

  const onClick = (ev) => {
    ev?.target?.select()
    betslipContext.setStakeSelectorActive(true)
  }

  const onBlur = async () => {
    betslipContext.setStakeSelectorActive(false)

    const parsedStake = parseIntlNumberFormat(betslipStake)
    if (!isNaN(parsedStake) && parsedStake > 0) {
      const success = await betslipContext.setStake(floorNumber(parsedStake), 'totalStake')
      if (!success) {
        setBetslipStake(stake)
        setBetslipStakeDisplayValue(stake)
      }
    } else {
      setBetslipStake(stake)
      setBetslipStakeDisplayValue(stake)
    }
  }

  const onKeyDownEvent = (event: any) => {
    if (event.key === 'Enter') event?.target?.blur()
  }

  return (
    <div
      className={`betslip-stake-selector flex w-full h-[42px] rounded-sm bg-gray-500 bg-opacity-10 hover:bg-opacity-20 border-[1px] 
      ${betslipContext.stakeSelectorActive ? 'border-primary' : 'border-transparent'} 
      ${disabled ? 'betslip-stake-selector-disabled opacity-60 cursor-not-allowed' : ''} 
      ${wrapperClassName}`}
    >
      <div className="betslip-stake-selector-inner flex relative w-full h-full px-2">
        <span className="betslip-stake-selector-label absolute top-[2px] left-[4px] text-xxs opacity-60 font-light">
          {t('BetSlip.totalStake')}
        </span>
        <NumericFormat
          className={`betslip-stake-selector-input text-right focus:outline-none appearance-none bg-transparent w-full pt-2 text-xs font-bold ${inputClassName}`}
          inputMode="decimal"
          value={betslipStakeDisplayValue}
          prefix={currencySlot === 'start' ? currencySymbol : ''}
          suffix={currencySlot === 'end' ? currencySymbol : ''}
          onChange={onStakeChange}
          onClick={onClick}
          onBlur={onBlur}
          onKeyDown={onKeyDownEvent}
          disabled={disabled}
          thousandSeparator={getIntlSeparator('group')}
          decimalSeparator={getIntlSeparator('decimal')}
          decimalScale={2}
          fixedDecimalScale
        />
      </div>
    </div>
  )
}

const BetSlipStakePerBetSelector = ({ disabled, inputClassName = '', wrapperClassName = '' }) => {
  const { t } = useTranslation('bets')
  const betslipContext = useBetslipContext()
  const currency = useUserCurrency()
  const currencySymbol = currency?.symbol ?? currency?.shortSign
  const currencySlot = getIntlCurrencySlot()
  const stakePerBet =
    betslipContext.chargedAmount > 0
      ? floorNumber(betslipContext.chargedAmount / Math.max(1, betslipContext.betAmount))
      : betslipContext.stake

  const [betslipStakePerBet, setBetslipStakePerBet] = useState(null)
  const [betslipStakePerBetDisplayValue, setBetslipStakePerBetDisplayValue] = useState(null)

  useEffect(() => {
    setBetslipStakePerBet(stakePerBet)
    setBetslipStakePerBetDisplayValue(stakePerBet)
  }, [betslipContext.chargedAmount, betslipContext.betAmount])

  const onStakeChange = (event: any) => {
    setBetslipStakePerBet((event?.target?.value as string)?.replace(currencySymbol, '') as any)
    setBetslipStakePerBetDisplayValue(event?.target?.value as string)
  }

  const onClick = (ev) => {
    ev?.target?.select()
    betslipContext.setStakeSelectorActive(true)
  }

  const onBlur = async () => {
    betslipContext.setStakeSelectorActive(false)

    const parsedStake = parseIntlNumberFormat(betslipStakePerBet)
    if (!isNaN(parsedStake) && parsedStake > 0) {
      const success = await betslipContext.setStake(floorNumber(parsedStake), 'stakePerBet')
      if (!success) {
        setBetslipStakePerBet(stakePerBet)
        setBetslipStakePerBetDisplayValue(stakePerBet)
      }
    } else {
      setBetslipStakePerBet(stakePerBet)
      setBetslipStakePerBetDisplayValue(stakePerBet)
    }
  }

  const onKeyDownEvent = (event: any) => {
    if (event.key === 'Enter') event?.target?.blur()
  }

  return (
    <div
      className={`betslip-stake-selector flex w-full h-[42px] rounded-sm bg-gray-500 bg-opacity-10 hover:bg-opacity-20 border-[1px]  
      ${betslipContext.stakeSelectorActive ? 'border-primary' : 'border-transparent'} 
      ${disabled ? 'betslip-stake-selector-disabled opacity-60 cursor-not-allowed' : ''}
      ${wrapperClassName}`}
    >
      <div className="betslip-stake-selector-inner flex relative w-full h-full px-2">
        <span className="betslip-stake-selector-label absolute top-[2px] left-[4px] text-xxs opacity-60 font-light">
          {t('BetSlip.stakePerBet')}
        </span>
        <NumericFormat
          className={`betslip-stake-selector-input text-right focus:outline-none appearance-none bg-transparent w-full pt-2 text-xs font-bold ${inputClassName}`}
          inputMode="decimal"
          value={betslipStakePerBetDisplayValue}
          prefix={currencySlot === 'start' ? currencySymbol : ''}
          suffix={currencySlot === 'end' ? currencySymbol : ''}
          onChange={onStakeChange}
          onClick={onClick}
          onBlur={onBlur}
          onKeyDown={onKeyDownEvent}
          disabled={disabled}
          thousandSeparator={getIntlSeparator('group')}
          decimalSeparator={getIntlSeparator('decimal')}
          decimalScale={2}
          fixedDecimalScale
        />
      </div>
    </div>
  )
}

export const BetSlipStakeSelector = ({ disabled, inputClassName = '', wrapperClassName = '' }) => {
  const betslipContext = useBetslipContext()

  return (
    <>
      {betslipContext.betType === BetTypes.Single ? (
        <BetSlipStakePerBetSelector
          disabled={disabled}
          inputClassName={inputClassName}
          wrapperClassName={wrapperClassName}
        />
      ) : (
        <BetSlipTotalStakeSelector
          disabled={disabled}
          inputClassName={inputClassName}
          wrapperClassName={wrapperClassName}
        />
      )}
    </>
  )
}
