import useTranslation from 'next-translate/useTranslation'
import { useBetslipContext } from '../../../context/betslip.context'
import { BetTypes } from '../../../services/bet/bet.models'

const BetTypeSelectorItem = ({ mode, name }) => {
  const betslipContext = useBetslipContext()
  const isActive = mode === betslipContext.betType

  const onChangeBetSlipMode = () => {
    betslipContext.setBetType(mode)
  }

  return (
    <div
      className="bet-type-selector-item flex grow justify-center text-sm p-4 relative cursor-pointer"
      onClick={onChangeBetSlipMode}
    >
      <span
        className={`bet-type-selector-item-name hover:opacity-100 ${
          isActive ? 'bet-type-selector-item-name-active font-bold' : 'opacity-70'
        }`}
      >
        {name}
      </span>
      {isActive && (
        <div
          className={`bet-type-selector-item-active-indicator absolute bottom-0 left-0 w-full h-[2px] bg-primary`}
        ></div>
      )}
    </div>
  )
}

export const BetTypeSelector = () => {
  const betslipContext = useBetslipContext()
  const { t } = useTranslation('bets')
  const activatedBetTypesCount = [
    betslipContext.isBetTypeAcitvated(BetTypes.Single),
    betslipContext.isBetTypeAcitvated(BetTypes.Combi),
    betslipContext.isBetTypeAcitvated(BetTypes.System),
  ]?.filter((type) => type === true)?.length

  return (
    <>
      {activatedBetTypesCount > 1 && (
        <div className="bet-type-selector flex border-b-[1px] border-neutral">
          {betslipContext.isBetTypeAcitvated(BetTypes.Single) && (
            <BetTypeSelectorItem mode={BetTypes.Single} name={t('BetSlip.betTypes.single')} />
          )}
          {betslipContext.isBetTypeAcitvated(BetTypes.Combi) && (
            <BetTypeSelectorItem mode={BetTypes.Combi} name={t('BetSlip.betTypes.combi')} />
          )}
          {betslipContext.isBetTypeAcitvated(BetTypes.System) && (
            <BetTypeSelectorItem mode={BetTypes.System} name={t('BetSlip.betTypes.system')} />
          )}
        </div>
      )}
    </>
  )
}
